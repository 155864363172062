import Vue from 'vue'
import { _ } from 'core-js'
import moment from "moment";


Vue.mixin({


  methods: {
    getUserPermissions(type = ''){
      if(localStorage.getItem('user')){
        let parsedObj = JSON.parse(localStorage.getItem('user')) 
        if(this.checkProperty(parsedObj,'roleId') == 3){
          return true
        }
        if(this.checkProperty(parsedObj,"modulePermissions")){
            let userPermissionsList = parsedObj.modulePermissions 
            const permissionArray = userPermissionsList[type]  
            if(permissionArray.length > 0 && permissionArray.indexOf(2) > -1){
              return true
            }else{
              return false
            }
        }else{
          return false
        }
      
      }
    },
    showToster({ message = '', isError = false }) {
      // alert(JSON.stringify(message))
      let duration = 3000
      try {
        this.$toast.clear()
      } catch (e) {

      }
      setTimeout(() => {


        if (isError) {

          this.$toast.open({
            message: message,
            type: "error",
            duration: duration,
            position: "top-right"
          });

        } else {
          this.$toast.open({
            message: message,
            type: "success",
            duration: duration,
            position: "top-right"
          });



        }
      }, 1)
    },
    // getInitials(firstName, lastName) {
    //   let initials = '';
    //   if (firstName) {
    //     initials += firstName.charAt(0).toUpperCase();
    //   }
    //   if (lastName) {
    //     initials += lastName.charAt(0).toUpperCase();
    //   }
    //   return initials;
    // },
    getInitials(name) {
      if (!name) return '';
      // Split the name by spaces
      const nameParts = name.split(' ');
      // Get the initials
      let initials = '';
      for (let i = 0; i < nameParts.length && i < 2; i++) {
        initials += nameParts[i].charAt(0).toUpperCase();
      }
      return initials;
    },


    checkProperty(object = null, mainKey = '', subKey = '', returnValue = '') {

      if (object != null && mainKey != '') {
        if (_.has(object, mainKey)) {

          if (subKey != '') {
            if (_.has(object[mainKey], subKey)) {
              returnValue = object[mainKey][subKey];
              if (subKey == 'countryCallingCode' && returnValue) {

                if (!(returnValue.includes("+"))) {
                  returnValue = "+" + returnValue
                }



              }


              return returnValue;
            } else {
              return returnValue;

            }


          } else {
            return object[mainKey];

          }


        } else {
          return returnValue;
        }


      } else {
        return returnValue;
      }
      //"phoneCountryCode" ,'countryCallingCode'

    },
    checkBoolean(object = null, mainKey = '', subKey = '') {
      let returnVal = false;
      let val = null
      if (object != null && mainKey != '') {
        if (subKey != '') {
          if (_.has(object[mainKey], subKey)) {
            val = object[mainKey][subKey]
            if (val == true || val == false || val == 'true' || val == 'false') {
              returnVal = true
            }
          }

        }
        else if (_.has(object, mainKey)) {
          val = object[mainKey]
          if (val == true || val == false || val == 'true' || val == 'false') {
            returnVal = true
          }
        }
      } else if (object == true || object == false || object == 'true' || object == 'false') {
        returnVal = true
      }
      return returnVal;
    },
    checkFieldIsRequired({ key = null, section = '', fieldsArray = null, required = false }) {
      let field = _.find(fieldsArray, { "section": section, "key": key })
      if (field) {
        //alert(section)


        if (_.has(field, 'required')) {
          if (field['required'] == true || field['required'] == 'true') {
            return true;

          } else {
            return false;

          }

        } else {
          return false;
        }


      } else if (required == true) {
        return required;
      } else if (key == null || fieldsArray == null || this.checkProperty(fieldsArray, 'length') <= 0) {
        return false;
      } else {
        return false;

      }


    },
    // downloads3file(value) {
    //   if (_.has(value, "path")) {
    //     value = Object.assign(value, { "url": value['path'] })
    //   }
    //   if (_.has(value, "url")) {
    //     value = Object.assign(value, { "path": value['url'] })
    //   }
    //   if (_.has(value, "petitionId")) {
    //     value = Object.assign(value, { "petitionId": value['petitionId'] })
    //   }
    //   value.url = value.url.replace(this.$globalgonfig._S3URL, "");
    //   value.url = value.url.replace(this.$globalgonfig._S3URLAWS, "");
    //   let postdata = { keyName: value.url };
    //   if (this.checkProperty(value, 'name')) {
    //     postdata = Object.assign(postdata, { 'fileName': value.name });
    //   }
    //   if (this.checkProperty(value, 'petitionId')) {
    //     postdata = Object.assign(postdata, { 'petitionId': value.petitionId });
    //   }

    //   // this.$vs.loading();
    //   this.$store.dispatch("getSignedUrl", postdata).then(response => {
    //     // this.$vs.loading.close();
    //     window.open(response.data.result.data, '_blank').focus();;
    //     //window.location.href = response.data.result.data;
    //     //  this.$vs.loading.close();
    //   });
    // },
    checkFileFormat(mimetype = '') {

      let return_val = 'commonfiles.png';
      let mimeTypes = {
        video_mime_types: [
          'audio/basic',
          'auido/L24',
          'audio/mid',
          'audio/mp4',
          'audio/x-aiff',
          'audio/x-mpegurl',
          'audio/vnd.rn-realaudio',
          'audio/ogg',
          'audio/vorbis',
          'audio/vnd.wav',
          'audio/mpeg',
          'application/annodex',
          'application/mp4',
          'application/ogg',
          'application/vnd.rn-realmedia',
          'application/x-matroska',
          'video/3gpp',
          'video/3gpp2',
          'video/annodex',
          'video/divx',
          'video/flv',
          'video/h264',
          'video/mp4',
          'video/mp4v-es',
          'video/mpeg',
          'video/mpeg-2',
          'video/mpeg4',
          'video/ogg',
          'video/ogm',
          'video/quicktime',
          'video/ty',
          'video/vdo',
          'video/vivo',
          'video/vnd.rn-realvideo',
          'video/vnd.vivo',
          'video/webm',
          'video/x-bin',
          'video/x-cdg',
          'video/x-divx',
          'video/x-dv',
          'video/x-flv',
          'video/x-la-asf',
          'video/x-m4v',
          'video/x-matroska',
          'video/x-motion-jpeg',
          'video/x-ms-asf',
          'video/x-ms-dvr',
          'video/x-ms-wm',
          'video/x-ms-wmv',
          'video/x-msvideo',
          'video/x-sgi-movie',
          'video/x-tivo',
          'video/avi',
          'video/x-ms-asx',
          'video/x-ms-wvx',
          'video/x-ms-wmx',
        ],
        pdf: ['application/pdf', "application/pdf"],
        msDoc: ['application/msword', "application/vnd.openxmlformats-officedocument.wordprocessingml.document"],
        msXl: [
          'application/excel',
          'application/x-excel',
          'application/x-msexcel',
          'application/vnd.ms-excel',
        ],
        msPpt: [
          'application/mspowerpoint',
          'application/vnd.ms-powerpoint',
          'application/powerpoint',
          'application/x-mspowerpoint',
        ],
        zip: [
          'application/x-rar-compressed',
          'application/octet-stream',
          'application/zip',
          'application/octet-stream',
          'application/x-zip-compressed',
          'multipart/x-zip'
        ],
        image: ['image/jpeg', 'image/png', 'image/jpg', 'image/gif', "image/ief", "image/jpeg"]

      }

      let fileNames = {
        video_mime_types: "film",
        pdf: "pdf",
        msDoc: "doc",
        msXl: "xls",
        msPpt: "ppt",
        zip: "zip-file-format",
        image: 'image'
      }
      try {
        _.forEach(fileNames, (val, key) => {


          if (_.has(mimeTypes, key) && mimetype) {
            if (mimetype.includes('image/')) {
              return_val = "image"
            } else if (mimeTypes[key].indexOf(mimetype) > -1) {
              return_val = key;
              // alert(key)
              //rturn ;
            }
          }

        })
        return return_val;
      } catch (e) {
        return_val = 'commonfiles.png';
        return return_val;

      }


    },
    updateLoading(value, reference = 'NoDataFoundRef') {
      try {

        if (this.$refs[reference]) {
          this.$refs[reference].updateLoading(value);

        }

      } catch (err) {
        console.log(err);

      }
    },

    isActivityCompleted(evaluation, actionCode) {
      let activityCompleted = false
      if (this.checkProperty(evaluation, 'completedActivities').indexOf(actionCode) > -1) {
        activityCompleted = true
      }

      return activityCompleted
    },
    getActivityLog(evaluation, actionCode) {
      let activitylog = null
      if (this.checkProperty(evaluation, 'evaluationActivityInfoLogs', 'length') > 0) {
        activitylog = _.find(this.checkProperty(evaluation, 'evaluationActivityInfoLogs'), function (item) {
          return item.action == actionCode;
        })

      }

      return activitylog
    },
    canCreateEvaluation(roleId) {
      if ([3, 4, 7].indexOf(roleId) > -1) {
        return true
      }
      return false
    },
    canCreateUser(roleId) {
      if ([3, 4].indexOf(roleId) > -1) {
        return true
      }
      return false
    },
    canCreateCustomer(roleId) {
      if ([3, 4].indexOf(roleId) > -1) {
        return true
      }
      return false
    },
    findmsDoctype(name = '', mimetype = '') {

      try {
        let ext = '';
        let arr = name.split(".");
        if (arr.length > 0) {
          ext = arr[arr.length - 1];
        }
        let docTypes = [
          'doc', "docx", "txt", "odt", "rtf", "dotm", "wps", "xml",
          "xl", "xlsx", "xla", "xlsb"
        ]
        let imagTypes = ['jpeg', 'jpg', 'png', "svg", "image/jpeg", "image/jpg", "image/png", "image/svg"];
        let pdfTypes = ['pdf'];
        if (_.findIndex(docTypes, (vl) => { return vl == ext }) != -1) {
          return "office";
        } else if ((_.findIndex(imagTypes, (vl) => { return vl == ext }) != -1) || imagTypes.indexOf(mimetype) > -1) {
          return "image";

        } else if (_.findIndex(pdfTypes, (vl) => { return vl == ext }) != -1 || mimetype == "application/pdf") {

          return "pdf";

        } else {
          return false;
        }
      } catch (err) {

        console.log(err);
        return false;
      }

    },
    customFormatter(date) {
      console.log(moment(new Date(date).setHours(0, 0, 0, 0)))
      return moment(new Date(date).setHours(0, 0, 0, 0)).format('MM/DD/YYYY');
    },
    pdftowordconvert(value, itemname) {
      if (_.has(value, "path")) {
        value = Object.assign(value, { "url": value['path'] })
      }
      if (_.has(value, "url")) {
        value = Object.assign(value, { "path": value['url'] })
      }
      if (_.has(value, "petitionId")) {
        value = Object.assign(value, { "petitionId": value['petitionId'] })
      }
      value.url = value.url.replace(this.$globalgonfig._S3URL, "");
      value.url = value.url.replace(this.$globalgonfig._S3URLAWS, "");
      let postdata = { keyName: value.url };
      if (this.checkProperty(value, 'name')) {
        postdata = Object.assign(postdata, { 'fileName': value.name });
      }
      // if(this.checkProperty(value ,'petitionId')){
      //   postdata = Object.assign(postdata ,{'petitionId':value.petitionId});


      // }

      //this.$vs.loading();
      this.$emit('convertLoading', true);
      this.$store.dispatch("pdftoword", postdata).then(response => {
        //  this.$vs.loading.close();
        //window.open(response.data.result.data, '_blank').focus();;
        //window.location.href = response.data.result.data;
        //  this.$vs.loading.close();
        this.$emit('convertLoading', false);
        const contentDisposition = response.headers['content-disposition'];
        let filename = 'default.docx'; // default name in case parsing fails

        // Use regex to extract filename from Content-Disposition header
        const regex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
        const matches = regex.exec(contentDisposition);
        if (matches != null && matches[1]) {
          filename = matches[1].replace(/['"]/g, '');
        }

        const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        a.download = itemname.replace('.pdf', '') + '.docx';

        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);

      });
    },
    downloads3file(value) {
      if (_.has(value, "path")) {
        value = Object.assign(value, { "url": value['path'] })
      }
      if (_.has(value, "url")) {
        value = Object.assign(value, { "path": value['url'] })
      }
      if (_.has(value, "petitionId")) {
        value = Object.assign(value, { "petitionId": value['petitionId'] })
      }
      value.url = value.url.replace(this.$globalgonfig._S3URL, "");
      value.url = value.url.replace(this.$globalgonfig._S3URLAWS, "");
      let postdata = { keyName: value.url };
      if (this.checkProperty(value, 'name')) {
        postdata = Object.assign(postdata, { 'fileName': value.name });
      }
      // if(this.checkProperty(value ,'petitionId')){
      //   postdata = Object.assign(postdata ,{'petitionId':value.petitionId});


      // }

      //this.$vs.loading();
      this.$store.dispatch("getSignedUrl", postdata).then(response => {
        //  this.$vs.loading.close();
        window.open(response.data.result.data, '_blank').focus();;
        //window.location.href = response.data.result.data;
        //  this.$vs.loading.close();
      });
    },

    isLastCompletedActivity(evaluation, actionCode) {
      let activityCompleted = false
      if (this.checkProperty(evaluation, 'previousActivity') == actionCode) {
        activityCompleted = true
      }
      // else if (this.checkProperty(evaluation, 'latestEvaluationLog', 'action') == actionCode) {
      //   activityCompleted = true
      // }
      return activityCompleted
    },

    getAssignedToDetails(assignedTo) {
      if (assignedTo && Array.isArray(assignedTo) && this.checkProperty(assignedTo, 'length') > 0) {
        return this.checkProperty(assignedTo[0], 'name')
      } else if (assignedTo && !Array.isArray(assignedTo)) {
        return this.checkProperty(assignedTo, 'name')
      }
      return ""
    },

    getAssignedToDetailsId(assignedTo) {
      if (assignedTo && Array.isArray(assignedTo) && this.checkProperty(assignedTo, 'length') > 0) {
        return this.checkProperty(assignedTo[0], '_id')
      } else if (assignedTo && !Array.isArray(assignedTo)) {
        return this.checkProperty(assignedTo, '_id')
      }
      return ""
    },
    canEditTheRecord(createdDate) {
      if (createdDate) {
        const date1 = moment(new Date(createdDate).setHours(0, 0, 0, 0));
        const date2 = moment(new Date().setHours(23, 59, 59, 0));
        const timeDifference = date2 - date1;
        const daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));

        return daysDifference <= 7;
        // return false
      }
      return true
    },
    getOrderTypes(orderTypes) {
      if (this.checkProperty(orderTypes, 'length') > 0) {
        return orderTypes.filter(item => item.id !== 5)
      }
      return []
    },



    handleFile(action, fileUrl,selectedOrder,fileName="") {
      if (action === 'download') {
        window.open(fileUrl, "_blank");
      } else if (action === 'share') {
        this.shareFile(fileUrl, selectedOrder,fileName);
      }
    },

  shareFile(fileUrl, selectedOrder,aFileName) {
      fetch(fileUrl)
        .then(response => {
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          return response.blob();
        })
        .then(blob => {
          let fileName = "Order_Invoice";
          if (aFileName) {
            fileName = aFileName;
          }

          let fileType, mimeType;
          switch (blob.type) {
            case 'application/pdf':
              fileType = 'pdf';
              mimeType = 'application/pdf';
              break;
            case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
              fileType = 'xlsx';
              mimeType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
              break;
            case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
              fileType = 'docx';
              mimeType = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
              break;
            case 'image/png':
              fileType = 'png';
              mimeType = 'image/png';
              break;
            case 'image/jpeg':
            case 'image/jpg':
              fileType = 'jpeg';
              mimeType = 'image/jpeg';
              break;
            case 'image/gif':
              fileType = 'gif';
              mimeType = 'image/gif';
              break;
            default:
              console.error('Unsupported file type');
              return;
          }
          const file = new File([blob], `${fileName}.${fileType}`, { type: mimeType });
          // console.log("file", file)
    
          if (navigator.canShare && navigator.canShare({ files: [file] })) {
            navigator.share({
              title: 'Kafeinfo',
              text: `Here are the details for order number ${fileName}.`,
              files: [file],
            })
              .then(() => console.log('File shared successfully!'))
              .catch(error => console.error('Error sharing file:', error));
          } else {
            console.error('Sharing files is not supported by your browser.');
          }
        })
        .catch(error => {
          console.error('There was a problem with the fetch operation:', error);
        });
 
    },
    






  },
  computed: {
    getUserRoleId() {
      // return 15;
      if (this.checkProperty(this.$store.state, 'user', 'roleId')) {
        return parseInt(this.$store.state.user.roleId);
      }
      return null
    },
    getUserData() {
      return this.$store.state.user
    },
    hasHistory() {
      return window.history.length > 2
    },
    getOfflineEvalStatusList() {
      return [
        {
          "_id": "64a8352a21ed77f30ccc1cf3",
          "id": 1,
          "name": "Requested"
        },
        {
          "_id": "64a8352a21ed77f30ccc1cf4",
          "id": 2,
          "name": "Reviewed"
        },
        {
          "_id": "64a8352a21ed77f30ccc1cf5",
          "id": 3,
          "name": "Confirmed"
        },




        {
          "_id": "64a8352a21ed77f30ccc1cfa",
          "id": 8,
          "name": "Template Assigned"
        },
        {
          "_id": "64a8352a21ed77f30ccc1cfb",
          "id": 9,
          "name": "Offshore"
        },
        {
          "_id": "64a8352a21ed77f30ccc1cfc",
          "id": 10,
          "name": "Onsite Review"
        },

        {
          "_id": "64a8352a21ed77f30ccc1cfe",
          "id": 12,
          "name": "With Professor"
        },
        {
          "_id": "64a8352a21ed77f30ccc1cff",
          "id": 13,
          "name": "Professor Revision"
        },
        {
          "_id": "64a8352a21ed77f30ccc1d00",
          "id": 14,
          "name": "Professor Approved"
        },
        {
          "_id": "64a8352a21ed77f30ccc1d01",
          "id": 15,
          "name": "Final Review"
        },
        {
          "_id": "64a8352a21ed77f30ccc1d02",
          "id": 16,
          "name": "Delivered"
        },
        {
          "_id": "64a8352a21ed77f30ccc1d03",
          "id": 17,
          "name": "Cancelled"
        }
      ]
    },
    getUserModules() {
      // return 15;
      if (this.checkProperty(this.$store.state, 'user', 'details')
        && this.checkProperty(this.$store.state.user, 'details', 'modules')) {
        return this.$store.state.user.details.modules;
      }
      return []
    },



  },
  mounted() {

  },
  watch: {

  }
})